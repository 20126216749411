<template>
  <div>
    <modal :show.sync="modals.scheduledMessageRemoveModal">
      <template slot="header">
        <h5 class="modal-title">Are you sure?</h5>
      </template>
      <div class="content mt--4 ml--1">
        You want to remove this Scheduled Message.
      </div>
      <template slot="footer">
        <base-button type="secondary" class="btn-gray" @click="modals.scheduledMessageRemoveModal=false">Cancel</base-button>
        <base-button type="danger" @click="onRemoveScheduleMessage">Remove</base-button>
      </template>
    </modal>
    <modal :show.sync="modals.addScheduledMessageModal">
      <h6 slot="header" class="modal-title" id="modal-title-default">Add New Schedule Notification</h6>

      <validation-observer v-slot="{handleSubmit}" ref="scheduledMessageForm">
        <form v-loading="loaders.modal" class="needs-validation" @submit.prevent="handleSubmit(addScheduledMessageToDb)">

          <div>
            <base-input label="Message" :rules="{required: true}" name="message">
                  <textarea v-model="scheduledMessage.form.message"
                            class="form-control"
                            rows="4">
                  </textarea>
            </base-input>
          </div>

          <div class="mt-4 row">
            <div class="col-6 pr-0">
              <base-input label="Date" :rules="{required: true}" name="date">
                <b-input-group>
                  <el-input
                    style="height: 40px; width: calc(100% - 40px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                    v-mask="'##/##/####'"
                    id="example-input"
                    v-model="scheduledMessage.form.date"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    autocomplete="off"
                    @blur="handleDate(this.scheduledMessage.form.date)"
                  ></el-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="datepicker-icon"
                      :min="minDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                      @context="onContextDate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </base-input>
            </div>
            <div class="col-6">
              <base-input label="Time*" :rules="{required: true}" name="time">
                <base-time-picker
                  v-model="scheduledMessage.form.time"
                />
              </base-input>
            </div>
          </div>
          <div class="scrollable checkbox-container-height">

            <div class="">
              <label class="lbl-select-stage">Select Stage</label>
            </div>

            <div class="stage_box pt-3 pl-3 d-inline-block">
              <el-checkbox v-model="stages['25']">Considering Surgery</el-checkbox>
            </div>

            <div class="stage_box pt-3 pl-3 ml-2 d-inline-block">
              <el-checkbox v-model="stages['50']">Pre-Op</el-checkbox>
            </div>

            <div class="stage_box pt-3 pl-3 d-inline-block mt-2">
              <el-checkbox v-model="stages['75']">Post-Op</el-checkbox>
            </div>

            <div class="stage_box pt-3 pl-3 ml-2 d-inline-block mt-2">
              <el-checkbox v-model="stages['150']">Non-Surgical</el-checkbox>
            </div>

            <template v-if="!categoriesEmpty">
              <template v-for="(category,index) in response.categories">
                <label class="lbl-select-stage pt-2 w-85 ellipsis-overflow-text"> {{ category.name }}</label>
                <template v-if="!subCategoriesEmpty(index)">
                  <el-checkbox-group
                    v-model="scheduledMessage.form.subCategories[category.id]"
                    :key="category.id"
                  >
                    <template v-for="(subCategory) in category.user_sub_categories">
                      <div class="categoryBox mt-2 pt-3 pl-3 d-inline-block ellipsis-overflow-text">
                        <el-checkbox :label="subCategory.id"
                                     :key="subCategory.id"
                        >
                          <span>{{ subCategory.name }}</span>
                        </el-checkbox>
                      </div>
                    </template>
                  </el-checkbox-group>
                </template>
                <div v-else>
                  <h3 class="text-center"> No {{ category.name }} Available </h3>
                </div>
              </template>
            </template>
          </div>

          <div class="float-right mt-4">
            <base-button type="secondary" class="btn-gray ml-auto" @click="modals.addScheduledMessageModal = false">
              Cancel
            </base-button>
            <base-button native-type="submit" type="submit" :loading="loaders.modal"
                         class="btn-primary-action btn-save-scheduled-message">
              Save
            </base-button>
          </div>

        </form>
      </validation-observer>
    </modal>
    <edit-scheduled-message-modal
      ref="editScheduleMessageModalRef"
      :scheduleMessageIid="scheduledMessageId"
      :categories="response.categories"
      @scheduleMessageUpdated="scheduleMessageUpdated"
    />
    <div class="m-0 pl-3 pt-4 pb-0 pr-2 d-flex justify-content-between align-items-center">
      <h1 class="ml-3 d-inline-block mt-2">Scheduled Messages</h1>
      <base-button
        size="md"
        class="mr-4 mt-2 title-btn-padding btn-secondary-action button-font"
        @click="toggleAddNewModal" type="secondary">
        Add New
      </base-button>
    </div>

    <base-header style="height: 130px">
    </base-header>
    <div v-loading="loaders.table" class="container-fluid mt--8 mb-5">
      <div class="content mt-3">
        <!-- Card -->
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div class="row">
            <div class="col-12 d-flex">
              <div class="table-responsive">
                <el-table :data="response.tblScheduleMessagesData"
                          row-key="id"
                          class="table-responsive table-flush"
                          header-row-class-name="thead-light"
                >
                  <el-table-column label="MESSAGE" prop="message" min-width="130px">
                    <template slot-scope="scope">
                      <div class="trim-text-overflow">{{ scope.row.message }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="STAGES" min-width="72px">
                    <template slot-scope="scope">
                      <div v-if="scope.row.stages.length > 1">
                        <span>{{ scope.row.stages[0] }},</span>
                        <el-popover
                          placement="bottom-end"
                          trigger="hover"
                          :visible-arrow="false"
                          width="fit-content"
                        >
                          <span slot="reference" class="tooltip">
                            +{{ scope.row.stages.length - 1 }} more
                          </span>

                          <span v-for="(item,index) in scope.row.stages">
                            <span class="tooltip-text" v-if="index !== 0">
                              {{ item }}
                            </span>
                          </span>

                        </el-popover>
                      </div>
                      <div v-else>
                        <span v-for="item in scope.row.stages">{{ item }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="PATIENT TAG" min-width="65px">
                    <template slot-scope="scope">
                      <div v-if="scope.row.subCategories.length > 1">

                        <span class="trim-text-overflow">{{ scope.row.subCategories[0].name }} ,</span>
                        <el-popover
                          placement="bottom-end"
                          trigger="hover"
                          :visible-arrow="false"
                          width="fit-content"
                        >
                          <span slot="reference" class="tooltip">
                            +{{ scope.row.subCategories.length - 1 }} more
                          </span>
                          <span v-for="(item,index) in scope.row.subCategories">
                            <span class="tooltip-text" v-if="index !== 0">
                              {{ item.name }}
                            </span>
                          </span>

                        </el-popover>

                      </div>
                      <div v-else>
                        <span v-for="item in scope.row.subCategories">{{ handleLongString(item.name) }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="DATE" prop="date" min-width="62px">
                    <template slot-scope="scope">
                      {{ getScheduleMessageDate(scope.row.date) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="ACTIONS" min-width="62px">
                    <div slot-scope="scope" class="d-flex">
                      <router-link :to="{name: 'ShowScheduledMessage', params: {id: scope.row.id}}">
                        <img class="pointer ml-2" src="/img/icons/buttons/show.svg" alt="Show Message Details">
                      </router-link>
                      <img class="pointer ml-2" src="/img/icons/buttons/delete.svg" alt="Delete Message"
                           @click="toggleRemoveModal(scope.row.id)">
                      <img class="pointer ml-2" :class="{'d-none': datePassed(scope.row.date)}"
                           src="/img/icons/buttons/edit.svg" alt="Edit Message" @click="toggleEditModal(scope.row.id)">
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <pagination-footer :pagination="pagination" @changePage="changePage"
                                 @changedPageLength="changedPageLength"/>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import PaginationFooter from "../../../Components/Pagination/PaginationFooter";
import {Option, Select, Table, TableColumn} from "element-ui";

import moment from "moment";
import EditScheduledMessageModal from "./EditScheduledMessageModal";

export default {
  name: "ScheduledMessages",
  components: {
    PaginationFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn, EditScheduledMessageModal
  },

  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      loaders: {
        modal: false,
        table: false
      },
      modals: {
        scheduledMessageRemoveModal: false,
        addScheduledMessageModal: false,
      },
      request: {
        api: {},
      },
      scheduledMessage: {
        form: {
          message: '',
          date: '',
          time: '',
          stages: [],
          subCategories: {}
        },
      },
      stages: {
        '25': false,
        '50': false,
        '75': false,
        '150': false,
      },
      scheduledMessageId: '',
      response: {
        tblScheduleMessagesData: [],
        stages: [],
        categories: {}
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
    }
  },

  mounted() {
    this.getScheduledMessages();
    this.fetchCategories();
  },

  computed: {
    categoriesEmpty() {
      return (typeof (this.response.categories) === 'undefined') ||
        (Object.keys(this.response.categories).length <= 0);
    }
  },

  methods: {

    handleDate(e) {
      if (moment(e).format('MM/DD/YYYY') == 'Invalid date') {
        this.scheduledMessage.form.date = '';
      }else if (moment(this.scheduledMessage.form.date) < moment(this.today)) {
        this.scheduledMessage.form.date = moment(this.today).format('MM/DD/YYYY');
      }
    },
    onContextDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.scheduledMessage.form.date = ctx.selectedFormatted
      }
    },
    addCategoryToApi() {
      if (!this.categoriesEmpty) {
        for (let i = 0; i < this.response.categories.length; i++) {
          this.$set(this.scheduledMessage.form.subCategories, `${this.response.categories[i].id}`, [])
        }
      }
    },

    changePage(page) {
      this.pagination.page = page;
      this.getScheduledMessages();
    },

    changedPageLength(pageLength) {
      this.pagination.perPage = pageLength;
      this.getScheduledMessages();
    },

    datePassed(date) {
      return (Date.parse(date) - Date.now() <= 0)
    },

    fetchCategories() {
      const vm = this
      vm.loaders.modal = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.categories = response.data.data
          this.addCategoryToApi();
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => vm.loaders.modal = false)
    },

    getScheduledMessages() {
      let vm = this;
      vm.loaders.table = true
      axios.get(this.$store.getters.getBaseUrl + '/api/scheduled-messages?page=' + vm.pagination.page + '&perPage=' + vm.pagination.perPage
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.tblScheduleMessagesData = [];
        vm.response.tblScheduleMessagesData = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
      }).catch(error => {
        console.log(error.response);
      }).finally(() => vm.loaders.table = false);
    },

    getScheduleMessageDate(date) {
      return moment(date).format('MMMM DD, YYYY')
    },

    handleLongString(longString, limit = 15) {
      if (longString===null){
        return ''
      }else
        return (longString.length > limit) ? longString.slice(0, limit) + '...' : longString
    },

    toggleAddNewModal() {
      this.modals.addScheduledMessageModal = !this.modals.addScheduledMessageModal;
    },

    addScheduledMessageToDb() {
      this.handleDate(this.scheduledMessage.form.date);
      var stagesArr = [];
      for (const x in this.stages) {
        if (this.stages[x]) {
          stagesArr.push(x);
        }
      }
      let vm = this;
      axios.post(vm.$store.getters.getBaseUrl + `/api/scheduled-messages/store`, {
        stages: stagesArr.join(),
        message: this.scheduledMessage.form.message,
        date: this.scheduledMessage.form.date,
        time: this.scheduledMessage.form.time,
        timeZoneOffset: new Date().getTimezoneOffset(),
        subCategories: this.scheduledMessage.form.subCategories
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.$notify.success({
            title: 'Success',
            message: 'Scheduled message added successfully.'
          });
          vm.scheduledMessage.form.message = '';
          vm.scheduledMessage.form.date = '';
          vm.scheduledMessage.form.time = '';
          vm.resetStages();
          vm.resetCategories();
          this.$refs.scheduledMessageForm.reset();
          vm.modals.addScheduledMessageModal = !vm.modals.addScheduledMessageModal;
          vm.getScheduledMessages();
        }).catch(error => {
        vm.$notify.error({
          title: 'Schedule Message',
          message: 'Something went wrong!'
        });
      }).finally(() => {

      });
    },

    resetStages() {
      this.stages['25'] = false;
      this.stages['50'] = false;
      this.stages['75'] = false;
      this.stages['150'] = false;
    },

    resetCategories() {
      for (const key of Object.keys(this.scheduledMessage.form.subCategories)) {
        this.scheduledMessage.form.subCategories[key].splice(0,this.scheduledMessage.form.subCategories[key].length)
      }
    },

    subCategoriesEmpty(categoryId) {
      return (typeof (this.response.categories[categoryId].user_sub_categories) === 'undefined') ||
        (Object.keys(this.response.categories).length <= 0) ||
        (this.response.categories[categoryId].user_sub_categories.length <= 0);
    },

    toggleEditModal(id) {
      this.scheduledMessageId = id;
      this.$refs.editScheduleMessageModalRef.resetFormFields();
      this.$refs.editScheduleMessageModalRef.getScheduledMessage(id);
      this.$store.commit('showEditScheduleMessageModal');
    },

    scheduleMessageUpdated() {
      this.scheduledMessageId = '';
      this.$store.commit('hideEditScheduleMessageModal');
      this.getScheduledMessages();
    },

    toggleRemoveModal(id) {
      this.scheduledMessageId = id;
      this.modals.scheduledMessageRemoveModal = !this.modals.scheduledMessageRemoveModal;
    },

    onRemoveScheduleMessage() {
      let vm = this;
      axios.delete(vm.$store.getters.getBaseUrl + `/api/scheduled-messages/${vm.scheduledMessageId}`)
        .then(() => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.$notify.success({
            title: 'Success',
            message: 'Scheduled Message Deleted'
          });
          vm.modals.scheduledMessageRemoveModal = !vm.modals.scheduledMessageRemoveModal;
          vm.scheduledMessageId = '';
          this.getScheduledMessages();
        }).catch(error => {
        vm.$notify.error({
          title: 'Schedule Message',
          message: 'Something went wrong!'
        });
      }).finally(() => {

      });
    },

  },
};
</script>

<style scoped>
.trim-text-overflow{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stage_box {
  width: 212px;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.tooltip {
  display: inline-block;
  opacity: 1;
  color: #832C44;
  margin-top: 2px;
  padding-left: 5px;
  height: 18px;
  z-index: inherit;
}

.tooltip-text {
  display: block;
  font: normal normal 600 12px/20px Open Sans;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000D18;
  background: #FFFFFF 0 0 no-repeat padding-box;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.btn-gray {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}

.lbl-select-stage {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.btn-save-scheduled-message {
  height: 40px;
  padding-right: 40px;
  padding-left: 40px;
}

.categoryBox {
  width: 212px;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

div[class~='categoryBox']:nth-child(even) {
  margin-left: 0.5rem;
}

.scrollable {
  overflow-y: auto;
}

.checkbox-container-height{
  height: 34vh;
  min-height: 276px;
}

.datepicker-icon >>> .dropdown-toggle-no-caret{
  background: transparent;
  border: transparent;
  padding: 8px 0 8px 17px;
}

</style>

<style>

</style>
